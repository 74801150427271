import React from 'react'

import Link from './link'

import logo from '../images/logo.svg'
import classes from '../styles/header.module.sass'

const Header = ({ linkTo, children }) => {
    const img = <img src={logo} className={classes.logo} alt="" />
    const linkProps = { to: linkTo, hidden: true }
    return (
        <header className={classes.root}>
            <div className={classes.logoWrapper}>
                {linkTo ? <Link {...linkProps}>{img}</Link> : img}
            </div>
            {children}
        </header>
    )
}

export default Header
