import React from 'react'
import classNames from 'classnames'

import classes from '../styles/typography.module.sass'

export const Small = props => <span className={classes.small} {...props} />
export const Label = props => <span className={classes.label} {...props} />

export const H2 = props => <Heading Component="h2" {...props} />
export const H3 = props => <Heading Component="h3" {...props} />
export const H4 = props => <Heading Component="h4" {...props} />
export const H5 = props => <Heading Component="h5" {...props} />

const Heading = ({ Component, id, tilt, gutterBottom = true, className, children }) => {
    const headingClassNames = classNames(classes.heading, classes[Component], className, {
        [classes.tiltLeft]: tilt === 'left',
        [classes.tiltRight]: tilt === 'right',
        [classes.noGutter]: !gutterBottom,
    })
    return (
        <Component id={id} className={headingClassNames}>
            {children}
        </Component>
    )
}
