import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import svgToDataURL from 'svg-to-dataurl'

import { H2 } from './typography'
import classes from '../styles/section.module.sass'

const bannerThemes = {
    green: ['#0da596', '#009181'],
    blue: ['#0090c2', '#0084b4'],
    pink: ['#ff70b8', '#fd66ad'],
}

function getWave(pos, color) {
    const paths = {
        top: 'M686 39C419-11 170 10 0 30v96h1440V30c-187 22-430 69-754 9z',
        bottom: 'M754 87c267 50 516 29 686 9V0H0v96c187-22 430-69 754-9z',
    }
    const svg = `<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 126">
        <path d="${paths[pos]}" fill="${color}"/>
    </svg>`
    return svgToDataURL(svg)
}

export const Section = ({ title, id, grid, withPattern, children }) => {
    const sectionClassNames = classNames(classes.section, {
        [classes.pattern]: !!withPattern,
        [classes.orange]: !!withPattern,
    })
    const sectionContentClassNames = classNames(classes.sectionContent, {
        [classes.grid]: !!grid,
    })
    return (
        <section className={sectionClassNames} id={id}>
            {title && <H2 className={classes.heading}>{title}</H2>}
            <div className={sectionContentClassNames}>{children}</div>
        </section>
    )
}

Section.defaultProps = {
    grid: false,
    withPattern: false,
}

export const Banner = ({ title, id, grid, theme, withPattern, overlap, children }) => {
    const [color, colorDark] = bannerThemes[theme]
    const rootClassNames = classNames(classes.banner, {
        [classes.overlap]: !!overlap,
    })
    const bannerClassNames = classNames(classes.section, classes.bannerContent, {
        [classes.pattern]: !!withPattern,
        [classes[theme]]: !!withPattern,
    })
    const bannerContentClassNames = classNames(classes.sectionContent, {
        [classes.grid]: !!grid,
    })
    const waveTop = getWave('top', color)
    const waveBottom = getWave('bottom', color)
    const style = {
        backgroundImage: `url(${waveTop}), url(${waveBottom})`,
        '--color-theme-dark': colorDark,
    }
    return (
        <section className={rootClassNames} id={id} style={style}>
            <div className={bannerClassNames} style={{ backgroundColor: color }}>
                {title && <H2 className={classes.heading}>{title}</H2>}
                <div className={bannerContentClassNames}>{children}</div>
            </div>
        </section>
    )
}

Banner.defaultProps = {
    theme: 'green',
    withPattern: false,
    grid: false,
    overlap: false,
}

Banner.propTypes = {
    theme: PropTypes.oneOf(Object.keys(bannerThemes)),
    grid: PropTypes.bool,
    withPattern: PropTypes.bool,
    overlap: PropTypes.bool,
}

export const Col = ({ full = false, children }) => {
    const colClasses = classNames({
        [classes.full]: full,
    })
    return <div className={colClasses}>{children}</div>
}
