import React from 'react'
import classNames from 'classnames'

import Link from './link'

import letterS from '../images/pool-s.svg'
import letterP from '../images/pool-p.svg'
import letterA from '../images/pool-a.svg'
import letterC from '../images/pool-c.svg'
import letterY from '../images/pool-y.svg'
import classes from '../styles/footer.module.sass'

const Footer = () => {
    const letters = { S: letterS, P: letterP, A: letterA, C: letterC, Y: letterY }
    return (
        <footer className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.logo} aria-label="spaCy">
                    {['S', 'P', 'A', 'C', 'Y'].map(letter => (
                        <img
                            width={100}
                            key={letter}
                            className={classNames(classes.letter, classes[`letter${letter}`])}
                            src={letters[letter]}
                            alt=""
                        />
                    ))}
                </div>
            </div>

            <div className={classes.meta}>
                <div className={classes.banner}>
                    &copy; 2019{' '}
                    <Link hidden to="https://explosion.ai">
                        Explosion
                    </Link>
                </div>
                <div className={classes.banner}>
                    <Link hidden to="https://josephinerais.com">
                        Illustrations: Josephine Rais
                    </Link>
                </div>
            </div>
            <svg className={classes.hidden}>
                <defs>
                    <clipPath
                        id="wave"
                        clipPathUnits="objectBoundingBox"
                        transform={`scale(${1 / 1440} ${1 / 500})`}
                    >
                        <path d="M686 39C419-11 170 10 0 30v96h1440V30c-187 22-430 69-754 9z" />
                        <rect x="0" y={126} width={1440} height={500 - 126} />
                    </clipPath>
                </defs>
            </svg>
        </footer>
    )
}

export default Footer
